<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
            Avisos
            <template v-slot:actions>
                <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})">
                    mdi-close
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <div style="background: transparent;padding: 16px; border-radius: 15px;">
            <v-card class="white">
                <block v-if="loading"></block>
                <v-card-text>
                    <div class="d-flex justify-lg-end">
                        <a href="#" class="mx-2" @click.prevent.stop="showModal = true; email = true">
                            <v-icon>mdi-email</v-icon>
                        </a>
                        <a href="#" class="mx-2" @click.prevent.stop="showModal = true">
                            <v-icon color="success">mdi-whatsapp</v-icon>
                        </a>
                        <a href="#" class="mx-2" @click.prevent.stop="showModal = true">
                            <v-icon>mdi-message</v-icon>
                        </a>
                    </div>

                    <v-row class="my-2">
                        <v-col cols="12" sm="12" md="6" lg="3">
                            <date-picker label="Desde"
                                         @dateSelected="(param) => (filterProducts.start = param)"
                            ></date-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="3">
                            <date-picker label="Hasta"
                                         @dateSelected="(param) => (filterProducts.end = param)"
                            ></date-picker>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>
                    <div class="position-relative">
                        <div class="d-flex filter-btn">
                            <v-bottom-sheet v-model="showFilter" inset>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="btnclean" v-bind="attrs" v-on="on">FILTRAR
                                    </v-btn>
                                </template>
                                <v-sheet class="text-center">
                                    <v-btn class="mt-6" text color="primary">Filtrar</v-btn>
                                    <v-btn class="mt-6" text color="error" @click="showFilter = !showFilter">cerrar
                                    </v-btn>
                                    <div class="my-3 pa-4">
                                        <div>
                                            <v-checkbox
                                                    label="All"
                                                    v-model="filterProducts.all"
                                            ></v-checkbox>
                                            <v-select outlined dense  :items="advertisements" item-text="State" item-value="State" attach
                                                      label="Estado" v-model="filterProducts.state"></v-select>
                                            <v-select outlined dense  :items="advertisements" item-text="City" item-value="City" attach
                                                      label="Ciudad" v-model="filterProducts.city"></v-select>


                                        </div>

                                    </div>

                                </v-sheet>
                            </v-bottom-sheet>
                        </div>
                        <!-- Needs filter -->
                        <DxDataGrid class="isScrolledByGeneralFilter" :data-source="advertisements"
                                    :show-row-lines="showRowLines"
                                    :show-borders="showBorders"
                                    :row-alternation-enabled="rowAlternationEnabled">
                            <DxScrolling row-rendering-mode="virtual"/>
                            <DxPaging :page-size="10"/>
                            <DxColumn data-field="Status" caption="Estado"></DxColumn>
                            <DxColumn data-field="ID_Customer" caption="Codigo de paciente"></DxColumn>
                            <DxColumn data-field="FirstName" caption="Nombre"></DxColumn>
                            <!--                                <DxColumn data-field="reason" caption="Reason"></DxColumn>-->
                            <DxColumn data-field="Email" caption="Correo"></DxColumn>
                            <DxColumn data-field="SystemDateUpdate" caption="Fecha de cita"></DxColumn>
                            <DxColumn data-field="PhoneNumber" caption="Numero de telefono"></DxColumn>
                                    
                            />
                        </DxDataGrid>
                    </div>


                </v-card-text>
            </v-card>
        </div>
        </v-expansion-panel-content>

        <v-dialog v-model="showModal" max-width="800">
            <modal>
                <template slot="title">Enviar recordatorio</template>
                <template slot="body">
                    <form>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field outlined dense 
                                        v-model="form.phone"
                                        label="Telefono" type="number"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="6">
                                <v-text-field outlined dense v-if="email"
                                              v-model="form.email"
                                              label="Correo" type="email">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <date-picker label="Desde"></date-picker>
                            </v-col>

                            <v-col cols="12" sm="12" md="3">
                                <date-picker label="Hasta"></date-picker>
                            </v-col>
                        </v-row>
                    </form>
                </template>
            </modal>
        </v-dialog>

    </v-expansion-panel>
</template>

<script>
    import DatePicker from "../../../components/DatePicker";
    import Modal from "../../../components/Modal";
    import {DxDataGrid, DxColumn, DxScrolling, DxPaging} from 'devextreme-vue/data-grid';
    import Block from "@/components/Block";

    export default {
        name: "Advertisement",
        components: {Modal, DatePicker, DxDataGrid, DxColumn, Block, DxScrolling, DxPaging},
        props: ['win'],
        data() {
            return {
                showFilter: false,
                showModal: false,
                loading: false,
                email: false,
                form: {},
                advertisements: [],
                filterProducts: {start: null, end: null, state: null, city: null, all: null},
                showRowLines: true,
                showBorders: true,
                rowAlternationEnabled: true
            }
        },
        watch: {
            'showModal'() {
                if (!this.showModal) {
                    this.email = false
                }
            },
            'filterProducts': {
                deep: true,
                handler: 'getAllAdvertisements'
            }

        },
        mounted() {
            this.getAllAdvertisements()
        },
        methods: {
            getAllAdvertisements() {
                this.loading = true;
                let filterObj = {...this.filterProducts};
                Object.keys(filterObj).map(key => {
                    if (filterObj[key] === true) delete filterObj[key]
                });
                this.$API.customers.getCustomers(filterObj)
                    .then(response => {
                        this.loading = false;
                        this.advertisements = response
                    })
            },
        }
    }
</script>

<style scoped>
    .filter-btn {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
    }
    .btnclean{
  background-color: #F29D35 !important;
  color: white;
}


</style>
